@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '~@nebular/theme/styles/globals';
@import '~@nebular/auth/styles/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';
@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();
  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();
  @include nb-overrides();
}

button {
  &:disabled {
    cursor: not-allowed !important;
  }
}

.page-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.filter-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;

  input {
    height: 40px;
    margin: 10px 20px 10px 0;
    padding: 5px 40px 5px 5px;
  }

  nb-select {
    margin: 10px 20px 10px 0;
  }
}

.pagination {
  justify-content: center;
}

.table th {
  border-top: none !important;;
}

.table td.bg-success,
.table td.bg-danger {
  color: #fff;
}

.table thead th {
  border-bottom: 1px solid #edf1f7;
  font-weight: 600;
}

.table > tbody td {
  border-top: 1px solid #edf1f7;
  border-bottom: 0;
}

.table-borderless {
  td,
  th {
    border: 0 !important;
  }
}

.page-item .page-link {
  text-decoration: none !important;
  color: #36f;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0.4375rem 1rem;

  &:hover {
    color: #36f !important;
    background: rgba(51, 102, 255, .3) !important;
  }
}

.page-item.active .page-link {
  background: #36f;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;

  input.error {
    border-color: red;
  }

  &.status-success {
    position: relative;

    &:before {
      content: "\f058";
      font-family: Font Awesome\ 5 Free, sans-serif;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(calc(-50% + 8px));
      color: #00d68f;
      font-size: 18px;
      animation: heartBeat .75s forwards;
    }
  }

  &.status-danger {
    position: relative;

    &:before {
      content: "\f057";
      font-family: Font Awesome\ 5 Free, sans-serif;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(calc(-50% + 8px));
      color: #ff3d71;
      font-size: 18px;
      animation: heartBeat .75s forwards;
    }
  }
}

@keyframes heartBeat {
  0% {
    transform: translateY(calc(-50% + 8px)) scale(1);
  }

  14% {
    transform: translateY(calc(-50% + 8px)) scale(1.2);
  }

  28% {
    transform: translateY(calc(-50% + 8px)) scale(1);
  }

  42% {
    transform: translateY(calc(-50% + 8px)) scale(1.2);
  }

  70% {
    transform: translateY(calc(-50% + 8px)) scale(1);
  }
}

.validator-error {
  position: absolute;
  bottom: -20px;
  left: 10px;
  font-size: 11px;
  color: #ff3d71;
  display: flex;
  flex-direction: column;

  &.big {
    bottom: -26px;
    line-height: 10px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.key-input {
  position: relative;
  overflow: hidden;
  cursor: pointer;

  .copy-wrapper {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(100%);
    transition: all .3s;
    background: rgba(255, 255, 255, .5);

    nb-icon {
      margin-right: 20px;
    }
  }

  &:hover {
    input {
      filter: blur(2px);
    }

    .copy-wrapper {
      transform: translateY(0);
    }
  }
}

.table-hover a {
    cursor: pointer;
}

.nb-theme-default nb-card.size-tiny {
  height: auto;
}

.table-hover tbody tr:hover {
  background-color: rgba(237, 241, 247, .6);
}

.table-hover tbody a:link {
  text-decoration: none;
}

.table-hover tbody > a {
  display: table-row;
  color: #212529;

  &:link, &:visited, &:hover, &:active {
    text-decoration: none;
  }
}

.table-hover tbody a:hover {
  background-color: rgba(237, 241, 247, .6);
  color: #212529;
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: none !important;
}

.table-hover > tbody > a:hover {
  --bs-table-accent-bg: none !important;
}

tbody > a > a,
tbody > tr > a {
  display: table-cell;
  padding: 0.75rem;
  border-top: 1px solid #edf1f7;
}

tbody > tr > a:hover,
tbody > a > td > a:hover,
tbody > tr > td > a:hover,
tbody > a > a:hover {
  color: #598bff !important;
  text-decoration: underline !important;
}

tbody > a > td > a:last-child,
tbody > tr > td > a:last-child {
  margin-left: 5px;
}

.nb-theme-default nb-spinner.status-danger {
  background-color: rgba(237, 241, 247, .51);
}

.ml-auto {
  margin-left: auto;
}

.nb-theme-default nb-accordion {
  box-shadow: none !important;
}

.ngx-slider {
  .ngx-slider-bubble {
    cursor: default;
    bottom: 30px !important;
    padding: 1px 5px !important;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    font-size: 11px !important;
    font-weight: 800;
    background: #007bff;
    border: 2px solid #fff;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 5px solid #0000;
      border-right: 5px solid #0000;
      border-top: 5px solid #007bff;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
    }
  }

  .ngx-slider-bar {
    background: #edf1f7;
  }

  .ngx-slider-pointer {
    width: 24px !important;
    height: 24px !important;
    background-color: #007bff !important;

    &:after {
      content: none !important;
    }

    &:focus {
      outline: 0;
    }
  }
}

.tabset {
  justify-content: center !important;
  flex-wrap: wrap;
}

nb-spinner {
  z-index: 8 !important;
}

.text-link {
  color: #36f !important;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.custom-toggle {
  margin: .25rem;

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin-bottom: 0;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ff3d71;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }

  .slider-gray {
    background-color: gray;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 34px;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #00d68f;
  }

  input:disabled + .slider {
    background-color: lightgray;
    cursor: not-allowed;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #00d68f;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}

.nb-theme-default nb-calendar-picker .day-cell {
  width: 2rem !important;
  height: 2rem !important;
  font-size: .75rem !important;
}

.nb-theme-default nb-calendar-with-time .timepicker-multiple-column-width {
  width: 6rem !important;
}

.nb-theme-default nb-calendar-picker {
  padding: 0 !important;
}

.nb-theme-default .nb-timepicker-container .list-item {
  font-size: .75rem !important;
  height: 2.75rem !important;
}

.nb-theme-default nb-base-calendar nb-card {
  width: 15rem !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #36f transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 992px) {
  .no-mobile {
    display: none;
  }

  nb-layout-header nav {
    padding: .75rem !important;
  }

  .tabset {
    .tab-link {
      font-size: .675rem !important;
    }
  }
  nb-tabset nb-tab {
    padding: .5rem !important;
  }

  .nb-form-field-prefix-medium,
  .nb-form-field-suffix-medium {
    height: 100% !important;
    width: 100% !important;
    min-width: 2.2rem;
  }

  .validator-error {
    line-height: 10px;
  }

  .table th,
  .table td {
    padding: .5rem;
    font-size: .75rem;
  }

  .page-item .page-link {
    font-size: .75rem;
    padding: .375rem .75rem;
  }
}

.nb-theme-default .cdk-overlay-container {
  z-index: 9999;
}

.reports-tabset {
  background: red;

  .tabset {
    justify-content: flex-start !important;
    width: 100%;
    height: 72px;
    background-color: #ffffff;
    border: 0.0625rem solid #e4e9f2;
    border-radius: 0.25rem;
    margin-bottom: 1.875rem !important;

    li {
      padding: 0 20px;
    }

    .tab-link {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #5E5E65;
      text-transform: unset;

      &:before {
        content: none !important;
      }
    }

    .active {
      .tab-link {
        &:after {
          content: " ";
          background-color: #5A73E9;
          position: absolute;
          left: 0;
          bottom: -16px;
          width: 100%;
          height: 7px;
          border-radius: 5px;
        }
      }
    }
  }

  .content-active {
    padding: 0;
    overflow: hidden;
  }
}

.toast-error {
  width: unset !important;
  min-width: 25rem;
}
